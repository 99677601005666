<template>
  <common-badge
    v-if="
      contentLength > 0 &&
      (appConfig.VueSettingsPreRun.SmallBasketShowMode === 'default' ||
        appConfig.VueSettingsPreRun.SmallBasketShowMode === 'total-amount')
    "
  >
    <template #content>
      <ui-icon
        color="mono1000"
        :size="24"
        name="shoppingCart"
        @click="() => emit('click')"
      />
    </template>
    <template #badge>
      {{ contentLength }}
    </template>
  </common-badge>

  <ui-icon
    v-else
    color="mono1000"
    :size="24"
    name="shoppingCart"
    @click="() => emit('click')"
  />
</template>

<script setup lang="ts">
import type { HeaderBasketButton } from '~types/props'

defineProps<HeaderBasketButton>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()
</script>
